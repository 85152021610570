import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './MoreReviews.module.css';
import TestimonialReview from './TestimonialReview';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import RatingStars from './RatingStars';

const propTypes = {
  heading: PropTypes.string,
  rating: PropTypes.number,
  subheading: PropTypes.string,
  reviewsAlwaysVisible: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(TestimonialReview.propTypes) })),
  buttonText: PropTypes.string,
  backgroundColor: PropTypes.string,
  headingColor: PropTypes.string,
  ratingTextColor: PropTypes.string,
  subheadingColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  itemHeadingFont: PropTypes.shape({ family: PropTypes.string }),
  itemTextFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  heading: null,
  rating: null,
  subheading: null,
  reviewsAlwaysVisible: null,
  items: [],
  buttonText: null,
  backgroundColor: null,
  headingColor: null,
  ratingTextColor: null,
  subheadingColor: null,
  buttonColor: null,
  buttonHoverColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  buttonTextColor: null,
  buttonHoverTextColor: null,
  starColor: null,
  starEmptyColor: null,
  headingFont: null,
  textFont: null,
  itemHeadingFont: null,
  itemTextFont: null,
};

export default function MoreReviews({
  heading,
  rating,
  subheading,
  reviewsAlwaysVisible,
  items,
  buttonText,
  backgroundColor,
  headingColor,
  ratingTextColor,
  subheadingColor,
  buttonColor,
  buttonHoverColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  buttonTextColor,
  buttonHoverTextColor,
  starColor,
  starEmptyColor,
  headingFont,
  textFont,
  itemHeadingFont,
  itemTextFont,
}) {
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const toggleShouldShowMore = useCallback(
    () => setShouldShowMore(!shouldShowMore),
    [shouldShowMore]
  );

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--rating-text-color', ratingTextColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--subheading-color', subheadingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-hover-color', buttonHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-hover-border-color',
            buttonHoverBorderColor,
            buttonHoverColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--button-hover-text-color',
            buttonHoverTextColor,
            'var(--text-color-light)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {heading && <h2 className={styles.heading}>{heading}</h2>}

      {!!rating && (
        <div className={styles.rating}>
          <div>{rating.toFixed(1)}</div>
          <div className={styles.star_wrapper}>
            <RatingStars rating={rating} starColor={starColor} starEmptyColor={starEmptyColor} />
          </div>
        </div>
      )}

      {subheading && <h4 className={styles.subheading}>{subheading}</h4>}

      <div className={styles.reviews}>
        {items.map(({ item }, i) => {
          if (i >= reviewsAlwaysVisible && !shouldShowMore) return null;
          return (
            <TestimonialReview
              key={i}
              {...{ headingFont: itemHeadingFont, textFont: itemTextFont, ...item }}
            />
          );
        })}
      </div>

      {!shouldShowMore && (
        <button className={styles.show_more_btn} onClick={toggleShouldShowMore}>
          {buttonText}
        </button>
      )}
    </div>
  );
}

MoreReviews.propTypes = propTypes;
MoreReviews.defaultProps = defaultProps;
