import React, { useEffect, useCallback, useState } from 'react';
import styles from './AutoplayCarousel.theme2.module.css';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, DotButton } from 'generic/CarouselButtons';
import { useRecursiveTimeout } from 'utils/useRecursiveTimeout';
import { buildCssVar } from 'utils/style-override';

const AUTOPLAY_INTERVAL = 3000;

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.element),
  buttonColor: PropTypes.string,
  showButtons: PropTypes.bool,
  showDots: PropTypes.bool,
  shouldAutoplay: PropTypes.bool,
  shouldLoop: PropTypes.bool,
  alignment: PropTypes.string,
};

const defaultProps = {
  items: null,
  buttonColor: null,
  showButtons: false,
  showDots: false,
  shouldAutoplay: true,
  shouldLoop: false,
  alignment: 'center',
};

const AutoplayCarouselTheme2 = ({
  items,
  buttonColor,
  showButtons,
  showDots,
  shouldAutoplay,
  shouldLoop,
  alignment,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: shouldLoop,
    align: alignment,
    containScroll: 'trimSnaps',
    slidesToScroll: 1,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  const scrollTo = useCallback(
    i => {
      if (!embla) return;
      embla.scrollTo(i);
      stop();
    },
    [embla, stop]
  );

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, stop]);

  useEffect(() => {
    if (shouldAutoplay) play();
  }, [play, shouldAutoplay]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className={`root ${styles.embla}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--button-color', buttonColor, '#8f8e8d')}
        }
      `}</style>

      <div className={styles.embla__viewport} ref={viewportRef}>
        <div className={styles.embla__container}>
          {items.map((item, i) => (
            <div className={styles.embla__slide} key={i}>
              <div className={`${styles.embla__slide__inner} embla_slide`}>{item}</div>
            </div>
          ))}
        </div>
      </div>
      {showButtons && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
      {showDots && (
        <div className={styles.embla__dots}>
          {scrollSnaps.map((_, i) => (
            <div key={i}>
              <DotButton key={i} isSelected={i === selectedIndex} onClick={() => scrollTo(i)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

AutoplayCarouselTheme2.prototype = propTypes;
AutoplayCarouselTheme2.defaultProps = defaultProps;

export default AutoplayCarouselTheme2;
