import { useMemo } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import DetailList from './DetailList';
import Video from './Video';
import Accordion from './Accordion.theme2';
import Comparison from './Comparison';
import FeaturedIn from './FeaturedIn';
import HeaderLogo from './HeaderLogo';
import Hero from './Hero';
import MultiProduct from './MultiProduct';
import StickyButton from './StickyButton';
import Story from './Story';
import AutoplayVideo from './AutoplayVideo.theme2';
import Guarantee from './Guarantee.theme2';
import MultiBundle from './MultiBundle.theme2';
import Testimonial from './Testimonial.theme2';
import ValueProposition from './ValueProposition.theme2';
import SimpleTestimonial from './SimpleTestimonial.theme2';
import HeadingText from './HeadingText.theme2';
import UpsellMiniCart from './UpsellMiniCart';
import MiniCart from './MiniCart';
import MultiVariantBundle from './MultiVariantBundle';
import HeadingImageText from './HeadingImageText';
import ImageOnly from './ImageOnly';
import Byline from './Byline';
import CtaButton from './CtaButton';
import Embed from './Embed';
import InteractQuiz from './InteractQuiz';
import Banner from './Banner';
import CountdownBanner from './CountdownBanner';
import ShowStickyButton from './ShowStickyButton';
import StickyButtonAnchor from './StickyButtonAnchor';
import StickyBanner from './StickyBanner';
import NavBar from './NavBar';
import TwoColumn from './TwoColumn';
import ImageCarousel from './ImageCarousel';
import LogosBanner from './LogosBanner';
import ReviewStars from './ReviewStars';
import Callout from './Callout';
import BulletList from './BulletList';
import ColumnImage from './ColumnImage';
import PhotoScrollerBanner from './PhotoScrollerBanner';
import QuantitySelector from './QuantitySelector';
import FunnelCart from './FunnelCart';
import TrustBoxes from './TrustBoxes';
import ContentModule from './ContentModule';
import MediaTextBlock from './MediaTextBlock';
import FinalCta from './FinalCta';
import Timeline from './Timeline';

const PeopleLooking = dynamic(() => import('./PeopleLooking'), { ssr: false });
// Map Directus sections to section components
const sectionComponents = {
  Video,
  Accordion,
  AutoplayVideo,
  Comparison,
  FeaturedIn,
  Guarantee,
  HeaderLogo,
  Hero,
  MultiProduct,
  MultiBundle,
  StickyButton,
  Story,
  Testimonial,
  ValueProposition,
  DetailList,
  SimpleTestimonial,
  UpsellMiniCart,
  MiniCart,
  MultiVariantBundle,
  HeadingImageText,
  ImageOnly,
  Byline,
  CtaButton,
  Embed,
  InteractQuiz,
  Banner,
  CountdownBanner,
  ShowStickyButton,
  StickyButtonAnchor,
  StickyBanner,
  NavBar,
  TwoColumn,
  ImageCarousel,
  LogosBanner,
  PeopleLooking,
  ReviewStars,
  HeadingText,
  Callout,
  BulletList,
  ColumnImage,
  QuantitySelector,
  FunnelCart,
  TrustBoxes,
  PhotoScrollerBanner,
  ContentModule,
  MediaTextBlock,
  FinalCta,
  Timeline,
};

const sectionPropTypes = {
  sectionData: PropTypes.object.isRequired,
};

const sectionDefaultProps = {};

// Display a section individually
const Section = ({ sectionData }) => {
  const SectionComponent = sectionComponents[sectionData.collection];
  if (!SectionComponent) return null;

  // Display the section
  return <SectionComponent {...sectionData.item} />;
};

Section.propTypes = sectionPropTypes;
Section.defaultProps = sectionDefaultProps;

////

const sectionsPropTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  showStickyButtonRef: PropTypes.shape({ current: PropTypes.any }),
};

const sectionsDefaultProps = {
  showStickyButtonRef: null,
};

// Display the list of sections
const SectionsTheme2 = ({ sections, showStickyButtonRef }) => {
  // TODO: Make this more generic such that components that care about another component, should be able to find it/its ref
  const sectionsWithStickyRef = useMemo(() => {
    let hasSticky = false;

    return (
      sections &&
      sections.map(section => {
        // Show the sticky button at the first section of the correct type
        if (!hasSticky && section.collection === 'ShowStickyButton') {
          hasSticky = true;
          return { ...section, item: { ...section.item, showStickyButtonRef } };
        }

        return section;
      })
    );
  }, [sections, showStickyButtonRef]);

  return (
    <>
      {/* Show the actual sections */}
      {sectionsWithStickyRef &&
        sectionsWithStickyRef.map(section => (
          <Section sectionData={section} key={`${section.collection}${section.id}`} />
        ))}
    </>
  );
};

SectionsTheme2.propTypes = sectionsPropTypes;
SectionsTheme2.defaultProps = sectionsDefaultProps;

export default SectionsTheme2;
