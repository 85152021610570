import PropTypes from 'prop-types';
import styles from './SimpleTestimonial.theme2.module.css';
import SimpleReview from './SimpleReview.theme2';
import { reviewPropTypes } from 'utils/prop-types';

const propTypes = {
  dividerText: PropTypes.string,
  reviews: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) }))
    .isRequired,
};

const defaultProps = {
  dividerText: null,
};

export default function SimpleTestimonial({ dividerText, reviews }) {
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
        }
      `}</style>

      <div
        className={`${styles.divider} ${
          dividerText ? styles.divider_with_text : styles.divider_without_text
        }`}
      >
        {dividerText}
      </div>

      <div className={styles.reviews_wrapper}>
        {reviews.map(({ item }, i) => (
          <SimpleReview key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

SimpleTestimonial.propTypes = propTypes;
SimpleTestimonial.defaultProps = defaultProps;
