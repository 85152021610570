import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

import styles from './MediaTextBlock.module.css';
import Image from './Image';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import RatingStars from './RatingStars';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const proptypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  mediaAlt: PropTypes.string,
  titleColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.object,
  headingFont: PropTypes.object,
  image: PropTypes.object,
  video: PropTypes.object,
  placeholderImage: PropTypes.object,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      heading: PropTypes.string,
      text: PropTypes.string,
      rating: PropTypes.string,
      reviewerName: PropTypes.string,
    })
  ),
};

const defaultProps = {
  sectionId: null,
  title: null,
  heading: null,
  text: null,
  mediaAlt: null,
  titleColor: null,
  headingColor: null,
  textColor: null,
  textFont: null,
  headingFont: null,
  image: null,
  video: null,
  placeholderImage: null,
  reviews: null,
};

function MediaTextBlock({
  sectionId,
  title,
  heading,
  text,
  mediaAlt,
  titleColor,
  headingColor,
  textColor,
  textFont,
  headingFont,
  image,
  video,
  placeholderImage,
  reviews,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const ReviewBlock = item => {
    return (
      <div>
        <h3 className={styles.review_heading}>{item.heading}</h3>

        {item.text && (
          <div className={styles.review_text}>
            <MarkdownText text={item.text} />
          </div>
        )}

        <div className={styles.review_rating}>
          {item.rating && (
            <div className={styles.icon_wrapper}>
              <RatingStars rating={item.rating} />
            </div>
          )}

          <p>{item.reviewerName}</p>
        </div>
      </div>
    );
  };

  return (
    <div id={sectionId} className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--title-color', titleColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--text-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}

          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.wrapper}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.block_wrapper}>
          <div className={styles.media_wrapper}>
            {image && <Image src={image} alt={mediaAlt} placeholder="blur" />}
            {video && (
              <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
            )}
          </div>
          <div className={styles.text_wrapper}>
            <h3 className={styles.heading}>{heading}</h3>
            {text && (
              <div className={styles.text}>
                <MarkdownText text={text} />
              </div>
            )}
            {!!reviews.length && (
              <div className={styles.reviews_wrapper}>
                {reviews.map(({ item }) => (
                  <ReviewBlock key={item.id} {...item} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

MediaTextBlock.propTypes = proptypes;
MediaTextBlock.defaultProps = defaultProps;

export default MediaTextBlock;
