import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import LazyAutoplayVideo from './LazyAutoplayVideo';
import Image from './Image';
import Bullet from './Bullet';
import styles from './AutoplayVideoItem.theme2.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  placeholderImage: PropTypes.object,
  video: PropTypes.object,
  image: PropTypes.object,
  mediaAlt: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(Bullet.propTypes) })),
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  taglineColor: PropTypes.string,
  taglineBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  placeholderImage: null,
  video: null,
  image: null,
  tagline: null,
  heading: null,
  text: undefined,
  bullets: [],
  headingColor: null,
  textColor: null,
  taglineColor: null,
  taglineBackgroundColor: null,
  headingFont: null,
  textFont: null,
};

export default function AutoplayVideoItem({
  placeholderImage,
  video,
  image,
  mediaAlt,
  tagline,
  heading,
  text,
  formattedText,
  bullets,
  headingColor,
  textColor,
  taglineColor,
  taglineBackgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.box_item}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--tagline-color', taglineColor, 'var(--text-color-dark)')}
          ${buildCssVar(
            '--tagline-background-color',
            taglineBackgroundColor,
            'var(--default-background-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.media_container}>
        {video && placeholderImage && (
          <div className={styles.video_container}>
            <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
          </div>
        )}
        {image && (
          <div className={styles.image_wrapper}>
            <Image src={image} alt={mediaAlt} placeholder="blur" />
          </div>
        )}
      </div>
      {tagline && (
        <div className={`${styles.tagline} ${taglineBackgroundColor ? styles.fancy_tagline : ''}`}>
          {tagline}
        </div>
      )}
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {text && (
        <div className={styles.text}>
          {!formattedText && text && <MarkdownText text={text} />}
          {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
        </div>
      )}
      <div className={styles.bullet_container}>
        {bullets.map(({ item }, i) => (
          <Bullet key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

AutoplayVideoItem.propTypes = propTypes;
AutoplayVideoItem.defaultProps = defaultProps;
