import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styles from './ValueProposition.theme2.module.css';
import Item from './ValuePropositionItem.theme2';

import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  sectionId: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  dividerText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string,
  taglineColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  dividerTextColor: PropTypes.string,
  dividerBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  sectionId: null,
  tagline: null,
  title: null,
  text: null,
  backgroundColor: null,
  taglineColor: null,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function ValueProposition({
  sectionId,
  tagline,
  title,
  text,
  dividerText,
  items,
  backgroundColor,
  taglineColor,
  headingColor,
  textColor,
  dividerTextColor,
  dividerBackgroundColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const rootClassNames = ['root', styles.container];
  if (backgroundColor) rootClassNames.push(styles.top_bottom_padding);

  return (
    <div id={sectionId} className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--tagline-color', taglineColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--divider-text-color', dividerTextColor, 'var(--text-color-light)')}
          ${buildCssVar(
            '--divider-background-color',
            dividerBackgroundColor,
            'var(--secondary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.value_proposition}>
        {tagline && <div className={styles.tagline}>{tagline}</div>}
        {title && <h1 className={styles.heading}>{title}</h1>}
        {text && (
          <div className={styles.text}>
            <MarkdownText text={text} />
          </div>
        )}
        <div className={styles.sec_wrapper}>
          {items.map(({ item }, i) => (
            <div key={i} className={styles.item_wrapper}>
              <Item {...item} />
              {dividerText && i !== items.length - 1 && (
                <div className={styles.divider_text}>
                  <p>{dividerText}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ValueProposition.propTypes = propTypes;
ValueProposition.defaultProps = defaultProps;
