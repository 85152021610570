import { reviewPropTypes } from 'utils/prop-types';
import dynamic from 'next/dynamic';
import RatingStars from './RatingStars';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import styles from './TestimonialReview.module.css';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = reviewPropTypes;

const defaultProps = {};

function TestimonialReview({
  userName,
  text,
  rating,
  userNameColor,
  textColor,
  backgroundColor,
  starColor,
  starEmptyColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--user-name-color', userNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.user_name}>
        {!!rating && (
          <div className={styles.stars}>
            <RatingStars rating={rating} starColor={starColor} starEmptyColor={starEmptyColor} />
          </div>
        )}
        <p>{userName}</p>
      </div>
      <div className={styles.text}>
        <MarkdownText text={text} />
      </div>
    </div>
  );
}

TestimonialReview.prototype = propTypes;
TestimonialReview.defaultProps = defaultProps;

export default TestimonialReview;
