import { FaCheckCircle } from 'react-icons/fa';
import dynamic from 'next/dynamic';
import RatingStars from './RatingStars';
import Image from './Image';
import styles from './SimpleReview.theme2.module.css';
import { reviewPropTypes } from 'utils/prop-types';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = reviewPropTypes;

const defaultProps = {
  userName: null,
  userImage: null,
  title: null,
  text: undefined,
  userNameColor: null,
  textColor: null,
  backgroundColor: null,
  verifiedColor: null,
  starColor: null,
  starEmptyColor: null,
};

export default function SimpleReview({
  userName,
  userImage,
  text,
  rating,
  userNameColor,
  textColor,
  backgroundColor,
  verifiedColor,
  starColor,
  starEmptyColor,
}) {
  return (
    <div className={`root ${styles.review}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--user-name-color', userNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${buildCssVar('--verified-color', verifiedColor, '#737391')}
        }
      `}</style>

      {userImage && (
        <div className={styles.user_img_wrapper}>
          <Image
            alt="User Image"
            src={userImage}
            width={200}
            height={200}
            placeholder="blur"
            sizes="200px"
          />
        </div>
      )}
      <div className={styles.review_text_wrapper}>
        <div className={styles.review_header}>
          <div className={styles.name_wrapper}>
            <RatingStars
              rating={rating}
              starColor={starColor ?? '#00BA75'}
              starEmptyColor={starEmptyColor}
            />
            <p className={styles.reviewer_name}>{userName}</p>
          </div>
          <div className={styles.verified_container}>
            <div className={styles.verified_icon}>
              <FaCheckCircle />
            </div>
            <p>Verified</p>
          </div>
        </div>

        {text && (
          <div className={styles.review_detail}>
            <MarkdownText text={text} />
          </div>
        )}
      </div>
    </div>
  );
}

SimpleReview.propTypes = propTypes;
SimpleReview.defaultProps = defaultProps;
