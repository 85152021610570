import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import styles from './Testimonial.theme2.module.css';
import TestimonialRating from './TestimonialRating.theme2';
import SocialReview from './SocialReview.theme2';
import MoreReviews from './MoreReviews';
import AutoplayCarouselTheme2 from './AutoplayCarousel.theme2';
import { reviewPropTypes } from 'utils/prop-types';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  sectionId: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ratings: PropTypes.arrayOf(
    PropTypes.shape({ item: PropTypes.shape(TestimonialRating.propTypes) })
  ),
  socialReviews: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) })),
  moreReviews: PropTypes.shape(MoreReviews.propTypes),
  taglineColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ratingsBackgroundColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
  itemHeadingFont: PropTypes.shape({ family: PropTypes.string }),
  itemTextFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  sectionId: null,
  tagline: null,
  title: null,
  text: undefined,
  ratings: [],
  socialReviews: [],
  moreReviews: null,
  taglineColor: null,
  headingColor: null,
  textColor: null,
  backgroundColor: null,
  ratingsBackgroundColor: null,
  headingFont: null,
  textFont: null,
  itemHeadingFont: null,
  itemTextFont: null,
};

export default function Testimonial({
  sectionId,
  tagline,
  title,
  ratings,
  text,
  socialReviews,
  moreReviews,
  taglineColor,
  headingColor,
  textColor,
  backgroundColor,
  ratingsBackgroundColor,
  headingFont,
  textFont,
  itemHeadingFont,
  itemTextFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const rootClassNames = ['root', styles.container];
  if (backgroundColor) rootClassNames.push(styles.top_bottom_padding);

  return (
    <div id={sectionId} className={rootClassNames.join(' ')}>
      <style jsx>{`
        .root {
          ${buildCssVar('--tagline-color', taglineColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar(
            '--ratings-background-color',
            ratingsBackgroundColor,
            'var(--default-background-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.testimonial_section}>
        <div className={styles.sec_wrapper}>
          {tagline && <p className={styles.tagline}>{tagline}</p>}
          {title && <h1 className={styles.testimonial_heading}>{title}</h1>}
          {text && (
            <div className={styles.testimonial_text}>
              <MarkdownText text={text} />
            </div>
          )}

          {!!ratings.length && (
            <div className={styles.ratings_wrapper}>
              {ratings.map(({ item }, i) => (
                <TestimonialRating key={i} headingFont={itemHeadingFont} {...item} />
              ))}
            </div>
          )}

          {!!socialReviews.length && (
            <div className={styles.testimonial_social_wrapper}>
              <AutoplayCarouselTheme2
                showButtons
                shouldLoop
                shouldAutoplay={false}
                alignment="start"
                items={socialReviews.map((review, i) => (
                  <div key={i} className={styles.carousel_item_wrapper}>
                    <SocialReview
                      review={{
                        ...review,
                        item: {
                          headingFont: itemHeadingFont,
                          textFont: itemTextFont,
                          ...review.item,
                        },
                      }}
                    />
                  </div>
                ))}
              />
            </div>
          )}

          {moreReviews && <MoreReviews {...{ itemHeadingFont, itemTextFont, ...moreReviews }} />}
        </div>
      </div>
    </div>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;
