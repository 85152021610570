import PropTypes from 'prop-types';
import Image from './Image';
import styles from './SocialReview.theme2.module.css';
import { reviewPropTypes } from 'utils/prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import RatingStars from './RatingStars';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  review: PropTypes.shape({ item: PropTypes.shape(reviewPropTypes) }),
};

const defaultProps = {
  review: {},
};

function SocialReview({ review }) {
  const {
    item: {
      userName,
      userImage,
      title,
      text,
      rating,
      userNameColor,
      headingColor,
      textColor,
      backgroundColor,
      verifiedColor,
      starColor,
      starEmptyColor,
      headingFont,
      textFont,
    },
  } = review;

  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--user-name-color', userNameColor, 'var(--text-color-dark)')}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${buildCssVar('--verified-color', verifiedColor, 'var(--color-positive)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {userImage && (
        <div className={styles.img_wrapper}>
          <Image src={userImage} alt="User Image" placeholder="blur" />
        </div>
      )}

      {!!rating && (
        <div className={styles.icon_wrapper}>
          <RatingStars rating={rating} starColor={starColor} starEmptyColor={starEmptyColor} />
        </div>
      )}

      <div className={styles.review_body}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>

      <div className={styles.user_name}>
        <p>{userName}</p>
      </div>

      <div className={styles.verified_container}>
        <div className={styles.verified_icon}>
          <FaCheckCircle />
        </div>
        <p>VERIFIED BUYER</p>
      </div>
    </div>
  );
}

SocialReview.prototype = propTypes;
SocialReview.defaultProps = defaultProps;

export default SocialReview;
