import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from './Image';
import styles from './ValuePropositionItem.theme2.module.css';
import { useTextFontOverrides, useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import Bullet from './Bullet';
import LazyAutoplayVideo from './LazyAutoplayVideo';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  placeholderImage: PropTypes.object,
  video: PropTypes.object,
  image: PropTypes.object,
  mediaAlt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(Bullet.propTypes) })),
  captionColor: PropTypes.string,
  captionBackgroundColor: PropTypes.string,
  headingColor: PropTypes.string,
  textColor: PropTypes.string,
  headingFont: PropTypes.shape({ family: PropTypes.string }),
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  placeholderImage: null,
  video: null,
  image: null,
  mediaAlt: null,
  caption: null,
  title: null,
  text: null,
  bullets: null,
  captionColor: null,
  captionBackgroundColor: null,
  headingColor: null,
  textColor: null,
  headingFont: null,
  textFont: null,
};

export default function ValuePropositionItem({
  placeholderImage,
  video,
  image,
  mediaAlt,
  caption,
  title,
  text,
  bullets,
  captionColor,
  captionBackgroundColor,
  headingColor,
  textColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <div className={`root ${styles.box_item}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--caption-color', captionColor, 'var(--text-color-dark)')}
          ${buildCssVar(
            '--caption-background-color',
            captionBackgroundColor,
            'var(--primary-color)'
          )}
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.media_container}>
        {caption && (
          <div className={styles.video_caption}>
            <div>{caption}</div>
          </div>
        )}
        {image && (
          <div className={styles.image_wrapper}>
            <Image src={image} alt={mediaAlt} placeholder="blur" />
          </div>
        )}
        {video && (
          <LazyAutoplayVideo alt={mediaAlt} placeholderImage={placeholderImage} video={video} />
        )}
      </div>

      {title && <h3 className={styles.heading}>{title}</h3>}
      {text && (
        <div className={styles.text}>
          <MarkdownText text={text} />
        </div>
      )}
      <div className={styles.bullet_container}>
        {bullets && bullets.map(({ item }, i) => <Bullet key={i} {...item} />)}
      </div>
    </div>
  );
}

ValuePropositionItem.propTypes = propTypes;
ValuePropositionItem.defaultProps = defaultProps;
