import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import { buildCssVar } from 'utils/style-override';
import { useHeadingFontOverrides, useTextFontOverrides } from 'utils/font-override';

import styles from './FinalCta.module.css';
import Image from './Image';
import Link from 'next/link';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.string,
  url: PropTypes.string,
  guaranteeText: PropTypes.string,
  buttonText: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonOnHoverColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonOnHoverTextColor: PropTypes.string,
  buttonOnHoverBorderColor: PropTypes.string,
  headingFont: PropTypes.object,
  textFont: PropTypes.object,
};

const defaultProps = {
  title: null,
  subtitle: null,
  url: null,
  image: null,
  text: null,
  guaranteeText: null,
  buttonText: null,
  backgroundColor: null,
  textColor: null,
  titlecolor: null,
  subtitlecolor: null,
  buttonColor: null,
  buttonTextColor: null,
  buttonOnHoverColor: null,
  buttonBorderColor: null,
  buttonOnHoverTextColor: null,
  buttonOnHoverBorderColor: null,
  headingFont: null,
  textFont: null,
};

function FinalCta({
  title,
  subtitle,
  image,
  text,
  formattedText,
  url,
  guaranteeText,
  buttonText,
  backgroundColor,
  textColor,
  titleColor,
  subtitleColor,
  buttonColor,
  buttonTextColor,
  buttonOnHoverColor,
  buttonBorderColor,
  buttonOnHoverTextColor,
  buttonOnHoverBorderColor,
  headingFont,
  textFont,
}) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--title-color', titleColor, 'var(--text-color-dark)')}
          ${buildCssVar('--subtitle-color', subtitleColor, 'var(--text-color-dark)')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-color', buttonColor, 'var(--primary-color)')}
          ${buildCssVar('--button-on-hover-color', buttonOnHoverColor, 'var(--primary-color)')}
          ${buildCssVar(
            '--button-on-hover-text-color',
            buttonOnHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar(
            '--button-border-color',
            buttonBorderColor,
            buttonColor,
            'var(--primary-color)'
          )}
          ${buildCssVar(
            '--button-on-hover-border-color',
            buttonOnHoverBorderColor,
            buttonOnHoverColor,
            'var(--primary-color)'
          )}
          ${headingFontOverrides ?? ''}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
        </div>
        <div className={styles.two_column}>
          {image && (
            <div className={styles.img_wrapper}>
              <Image alt="product" src={image} />
            </div>
          )}
          <div className={styles.text_wrapper}>
            {!formattedText && text && <MarkdownText text={text} />}
            {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
            {url && (
              <div className={styles.btn}>
                <Link href={url}>{buttonText}</Link>
              </div>
            )}
            {guaranteeText && <p className={styles.guarantee_text}>{guaranteeText}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

FinalCta.propTypes = propTypes;
FinalCta.defaultProps = defaultProps;

export default FinalCta;
